var render = function () {
  var _this$form_realisasi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "form_masuk",
    attrs: {
      "id": "form_masuk"
    }
  }, [_vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-row', {
    key: _vm.formRender,
    staticClass: "mb-1",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Ubah Status ")]), _vm.$router.currentRoute.params.id ? _c('b-dropdown', {
    attrs: {
      "id": "status",
      "variant": _vm.formStatusVariant(_vm.form.schedule.form_status)
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.form.schedule.form_status || 'Tidak ada status')
          }
        })];
      },
      proxy: true
    }], null, false, 2060410950)
  }, [_vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' ? _c('b-dropdown-header', {
    attrs: {
      "id": "dropdown-header-label"
    }
  }, [_c('small', {
    staticClass: "text-secondary"
  }, [_vm._v(_vm._s(_vm.$can('SAL', '') ? 'Ubah status ke' : 'Hanya Admin SAL'))])]) : _vm._e(), _vm.$can('SAL', '') && _vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' ? _c('b-dropdown-item', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onUpdateStatus(true, _vm.form.schedule.form_status);
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'danger'
    }
  }, [_vm._v(" BATALKAN KEGIATAN ")])], 1) : _vm.$can('SAL', '') ? _c('b-dropdown-item', {
    attrs: {
      "variant": "info"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v(" Tidak Dapat Mengubah Status yang Telah Dibatalkan. ")])], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.$router.currentRoute.params.id !== undefined ? _c('b-col', {
    staticClass: "text-center d-none d-sm-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('h1', [_vm._v("HULU MIGAS")])]) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "variant": "outline-secondary",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _c('span', [_vm._v("Kembali")])], 1)], 1)])], 1), _c('validation-observer', {
    ref: "createHuluMigasRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_c('form-wizard', {
    key: "wizardKey",
    ref: "wizardFirst",
    class: _vm.isLastStep() ? _vm.dataID ? 'vertical-steps steps-transparent mb-3 sal-hide' : 'vertical-steps steps-transparent mb-3 sal' : 'vertical-steps steps-transparent mb-3 sal',
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "lazy": true,
      "layout": "vertical",
      "before-change": function beforeChange() {
        return true;
      },
      "finish-button-text": "Terbitkan Form Perencanaan Kedaatangan Kapal",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted,
      "on-change": _vm.onTabChange
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "wizard-footer-left"
        }, [props.activeTabIndex > 0 && !props.isLastStep ? _c('wizard-button', {
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.prevTab();
            }
          }
        }, [_vm._v("Previous")]) : _vm._e()], 1), _c('div', {
          staticClass: "wizard-footer-right"
        }, [!props.isLastStep ? _c('wizard-button', {
          staticClass: "wizard-footer-right",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.nextTab();
            }
          }
        }, [_vm._v("Next")]) : _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button",
          style: props.fillButtonStyle,
          attrs: {
            "disabled": _vm.inProgressTerbitkanForm
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.formSubmitted.apply(null, arguments);
            }
          }
        }, [_vm.inProgressTerbitkanForm ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" " + _vm._s(props.isLastStep ? 'Terbitkan Form Perencanaan Kedatangan Kapal' : 'Next') + " ")], 1)], 1)];
      }
    }])
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step1.title,
      "icon": _vm.wizard.first.step1.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step1.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step1.content.subtitle) + ".")])])])], 1), _c('b-form-group', {
    attrs: {
      "label": "Jenis Kapal *",
      "label-for": "vi-schedule-jenis_kapal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jenis Kapal",
      "vid": "Jenis Kapal",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-jenis_kapal",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jenis_kapals,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.isExistDetailID ? true : null,
            "label": "text",
            "placeholder": "Pilih Jenis Kapal",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.form.schedule.jenis_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "jenis_kapal", $$v);
            },
            expression: "form.schedule.jenis_kapal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.form.schedule.jenis_kapal !== 'Tug Boat' && !!_vm.form.schedule.jenis_kapal ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Kapal*",
      "label-for": "vi-agen-name"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nama Kapal*",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-boat-name",
            "suggestions": [{
              data: _vm.form.dm_river_boat_hm.dm_river_boat_hms
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Boat Name',
              disabled: _vm.permissionSuggestionBoatMain()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_river_boat_hm', 'boat_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_river_boat_hm/',
                model: 'dm_river_boat_hm',
                currentSuggest: 'boat_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var suggestion = _ref3.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.boat_name)[0] + Array.from(suggestion.item.boat_name)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.boat_name, " , ").concat(suggestion.item.no_pkk))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("GT: ".concat(suggestion.item.gt || '', ", LOA: ").concat(suggestion.item.loa || '', " - ").concat(suggestion.item.dm_shipping_agent.shipping_name || ''))
                }
              })])]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Capt. ".concat(suggestion.item.captain_name, ", Telp: ").concat(suggestion.item.captain_telp))
                }
              })])]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_river_boat_hm.boat_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm, "boat_name", $$v);
            },
            expression: "form.dm_river_boat_hm.boat_name"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_river_boat_hmState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_river_boat_hmState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_river_boat_hm.dm_river_boat_hms.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3433809721)
  }), _vm.dm_river_boat_hmStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _vm.statePayloadDMKMaster ? _c('b-col', {
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKMaster({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span')], 1)], 1) : _vm._e(), _vm.statePayloadDMKMaster ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.jwtGantiKapal();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _c('span', [_vm._v("Simpan")])], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "GT ( Kapal ) *",
      "label-for": "vi-dm_river_boat_hm-gt"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "GT ( Kapal ) *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat_hm-gt",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionSuggestionBoat(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.dm_river_boat_hm.gt,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm, "gt", $$v);
            },
            expression: "form.dm_river_boat_hm.gt"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3814139288)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LOA ( Kapal ) *",
      "label-for": "vi-dm_river_boat_hm-loa"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "LOA ( Kapal ) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "M"
          }
        }, [_c('b-form-input', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat_hm-loa",
            "type": "number",
            "placeholder": "78,12 (comma manual)",
            "readonly": _vm.permissionSuggestionBoat()
          },
          model: {
            value: _vm.form.dm_river_boat_hm.loa,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm, "loa", $$v);
            },
            expression: "form.dm_river_boat_hm.loa"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3773900403)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Name *",
      "label-for": "vi-dm_river_boat_hm-captain_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Capt. Name *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_river_boat_hm-captain_name",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Full Capt. Name",
            "readonly": _vm.permissionSuggestionBoat()
          },
          model: {
            value: _vm.form.dm_river_boat_hm.captain_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm, "captain_name", $$v);
            },
            expression: "form.dm_river_boat_hm.captain_name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 654109249)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Telp *",
      "label-for": "vi-dm_river_boat_hm-captain_telp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Capt. Telp *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-dm_river_boat_hm-captain_telp",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "081145778613",
            "readonly": _vm.permissionSuggestionBoat()
          },
          model: {
            value: _vm.form.dm_river_boat_hm.captain_telp,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm, "captain_telp", $$v);
            },
            expression: "form.dm_river_boat_hm.captain_telp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 789859847)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PKK *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: PKK.DN.IDSRI.2208.000123"
          },
          model: {
            value: _vm.form.schedule.no_pkk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pkk", $$v);
            },
            expression: "form.schedule.no_pkk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.schedule.no_pkk ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'no_pkk' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 364650605)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan Shipping Agent *",
      "label-for": "vi-dm_shipping_agent-shipping_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Perusahaan Shipping Agent *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_shipping_agent-shipping_name",
            "get-suggestion-value": _vm.getSuggestionValue,
            "suggestions": [{
              data: _vm.form.dm_river_boat_hm.dm_shipping_agent.dm_shipping_agents || []
            }],
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control form-input',
              placeholder: 'Cth: PT LINTAS SERAM M',
              disabled: _vm.permissionSuggestionBoat()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_river_boat_hm', 'dm_shipping_agent', 'dm_shipping_agent.shipping_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_shipping_agent/',
                model: 'dm_river_boat_hm',
                currentSuggest: 'dm_river_boat_hm',
                childSuggesst: 'dm_shipping_agent.shipping_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var suggestion = _ref10.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.shipping_name))])])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_river_boat_hm.dm_shipping_agent.shipping_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_river_boat_hm.dm_shipping_agent, "shipping_name", $$v);
            },
            expression: "\n                          form.dm_river_boat_hm.dm_shipping_agent\n                            .shipping_name\n                        "
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_shipping_agentState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_shipping_agentState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_river_boat_hm.dm_shipping_agent.dm_shipping_agents.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_river_boat_hm.dm_shipping_agent.shipping_name ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'shipping_name' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2502631619)
  }), _vm.dm_shipping_agentStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)], 1), !_vm.isExistDetailID && !_vm.form.dm_river_boat_hm.isNew ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.breakSuggestionValue();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )')
    }
  })], 1), _c('span', [_c('small', [_c('strong', [_vm._v("Abaikan jika data master kapal yang otomatis teriisi sudah benar")])])])], 1)]) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMK === null && !_vm.statePayloadDMKMaster || _vm.statePayloadDMK === _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block",
    attrs: {
      "variant": "outline-danger",
      "block": "",
      "disabled": !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMK({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Buat data baru')
    }
  })], 1), _c('span', [_vm._v("Contoh kasus: data kapal belum pernah dilakukan penginputan.")])], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMK === null && !_vm.statePayloadDMKMaster || _vm.statePayloadDMK === _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block",
    attrs: {
      "variant": "outline-warning",
      "block": "",
      "disabled": !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMK({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Revisi data master kapal, Jika ada data yang tidak sesuai.')
    }
  })], 1), _c('span', [_vm._v("Contoh kasus: kesalahan penulisan")])], 1) : _vm._e(), _vm.statePayloadDMK ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.updateDMK({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('')
    }
  })], 1)], 1) : _vm._e(), _vm.statePayloadDMK ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "block": "",
      "variant": "success",
      "disabled": _vm.loadingDupRev
    },
    on: {
      "click": function click($event) {
        return _vm.saveToUpdateDMK();
      }
    }
  }, [!_vm.loadingDupRev ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRev ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan')
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "mb-1"
  })], 1), _vm.form.schedule.jenis_kapal === 'Tug Boat' || _vm.form.schedule.jenis_kapal === 'Barge' || false ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step2.title,
      "icon": _vm.wizard.first.step2.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step2.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step2.content.subtitle) + ".")])])])], 1), !_vm.form.form_tug_boat_id && _vm.$router.currentRoute.params.id ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Jettyman terlebih dahulu melakukan ganti atau pilih "), _c('strong', [_vm._v("Tug Boat")]), _vm._v(" untuk keberangkatan. ")])]) : _vm._e(), !_vm.form.form_tug_boat_id ? _c('br') : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name Tug Boat *",
      "label-for": "vi-name_tug_boat"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Name Tug Boat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-name",
            "suggestions": [{
              data: _vm.handlingTugBoat()
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Boat Name',
              disabled: _vm.permissionSuggestionTugBoatMain()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'name_tug_boat');
            },
            "input": function input($event) {
              _vm.searchForm({
                keyword: $event,
                uri: "vw_tug_boat/".concat(_vm.$can('manage', 'jetty') ? 'mobile_hm' : 'Hulu Migas'),
                model: 'tug_boat',
                currentSuggest: 'name_tug_boat'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var _suggestion$item$form, _suggestion$item$last, _suggestion$item$form2, _suggestion$item$form3;
              var suggestion = _ref12.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.name_tug_boat)[0] + Array.from(suggestion.item.name_tug_boat)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.name_tug_boat, ", ").concat(suggestion.item.no_pkk_tug_boat))
                }
              }), suggestion.item.tug_status === 'Dalam Perencanaan' && suggestion.item.model_status === 'Tersedia' ? _c('div', [suggestion.item.tug_status === 'Dalam Perencanaan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Dalam Perencanaan' ? 'danger' : suggestion.item.form_status === 'KAPAL SANDAR' ? 'info' : 'warning'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.form_status === 'KAPAL SANDAR' ? !_vm.dataID ? 'Opsi ganti kapal' : 'Opsi dapat Digunakan' : 'Sedang Direncanakan'))
                }
              })]) : _vm._e()], 1) : _c('div', [suggestion.item.model_status === 'Sedang Digunakan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Sedang Digunakan' ? 'danger' : 'secondary'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s(suggestion.item.model_status)
                }
              })]) : _vm._e(), _c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(!suggestion.item.sedang_digunakan ? '' : " : ".concat(suggestion.item.sedang_digunakan)))
                }
              })], 1), _c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("#".concat((_suggestion$item$form = suggestion.item.form_chain_id) !== null && _suggestion$item$form !== void 0 ? _suggestion$item$form : '-', "#").concat((_suggestion$item$last = suggestion.item.last_used_by_model_id) !== null && _suggestion$item$last !== void 0 ? _suggestion$item$last : '-', "#").concat((_suggestion$item$form2 = suggestion.item.form_tug_boat_id) !== null && _suggestion$item$form2 !== void 0 ? _suggestion$item$form2 : '-'))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat((_suggestion$item$form3 = suggestion.item.form_status) !== null && _suggestion$item$form3 !== void 0 ? _suggestion$item$form3 : "No Status ( ".concat(!_vm.dataID ? '' : 'Buat Rencana Kedatangan Kapal Terlebih dahulu', " )")))
                }
              })])]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Updated AT ".concat(_vm.formatDateTime(suggestion.item.updated_at)))
                }
              })])])])])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "name_tug_boat", $$v);
            },
            expression: "form.tug_boat.name_tug_boat"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.tug_boatState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.tug_boatState]) + " ")])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.tug_boats.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.name_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'name_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 288995806)
  }), _vm.tug_boatStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "GT ( Kapal ) *",
      "label-for": "vi-tug_boat-gt_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " GT ( Kapal ) *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-gt_tug_boat",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.tug_boat.gt_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "gt_tug_boat", $$v);
            },
            expression: "form.tug_boat.gt_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 239610526)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LOA ( Kapal ) *",
      "label-for": "vi-tug_boat-loa_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " LOA ( Kapal ) *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "M"
          }
        }, [_c('b-form-input', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-loa_tug_boat",
            "type": "number",
            "placeholder": "78,12 (comma manual)",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.loa_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "loa_tug_boat", $$v);
            },
            expression: "form.tug_boat.loa_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1341104053)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Name *",
      "label-for": "vi-tug_boat-captain_name_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Name *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-captain_name_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Full Capt. Name",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_name_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_name_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2923326535)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Telp *",
      "label-for": "vi-tug_boat-captain_telp_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Telp *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-captain_telp_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "081145778613",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_telp_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_telp_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_telp_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1035077217)
  })], 1)], 1), !_vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Nomor PKK *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: PKK.DN.IDSRI.2208.000123"
          },
          model: {
            value: _vm.form.schedule.no_pkk_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pkk_tug_boat", $$v);
            },
            expression: "form.schedule.no_pkk_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.schedule.no_pkk_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'no_pkk_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2528434202)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": !_vm.statePayloadDMKTBMaster ? 6 : 12,
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan Shipping Agent *",
      "label-for": "vi-dm_shipping_agent-shipping_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Perusahaan Shipping Agent *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_shipping_agent-shipping_name",
            "get-suggestion-value": _vm.getSuggestionValue,
            "suggestions": [{
              data: _vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents || []
            }],
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control form-input',
              placeholder: 'Cth: PT LINTAS SERAM M',
              disabled: _vm.permissionSuggestionTugBoat()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'dm_shipping_agent', 'dm_shipping_agent.shipping_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_shipping_agent/',
                model: 'tug_boat',
                currentSuggest: 'tug_boat',
                childSuggesst: 'dm_shipping_agent.shipping_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref19) {
              var suggestion = _ref19.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.shipping_name))])])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.dm_shipping_agent.shipping_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat.dm_shipping_agent, "shipping_name", $$v);
            },
            expression: "\n                          form.tug_boat.dm_shipping_agent.shipping_name\n                        "
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_shipping_agentState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_shipping_agentState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.dm_shipping_agent.shipping_name ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'shipping_name' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 1963834189)
  }), _vm.dm_shipping_agentStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)], 1), _vm.form.schedule.jenis_kapal === 'Tug Boat' ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Schedule")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Kapal *",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Kapal",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Asal Kapal',
              disabled: _vm.permissionTenantAndSAL()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref21) {
              var suggestion = _ref21.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 1097423609)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Posisi Jetty *",
      "label-for": "vi-schedule-rencana_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Posisi Jetty",
      "vid": "Rencana Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.tug_boat.rencana_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_position_jetty", $$v);
            },
            expression: "form.tug_boat.rencana_position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2799873950)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Baris Posisi Jetty *",
      "label-for": "vi-schedule-rencana_baris_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Baris Posisi Jetty",
      "vid": "Rencana Baris Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var errors = _ref23.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_baris_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Baris Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.tug_boat.rencana_baris_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_baris_position_jetty", $$v);
            },
            expression: "\n                              form.tug_boat.rencana_baris_position_jetty\n                            "
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1643161761)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar Tug Boat *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar Tug Boat *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_vm.form.schedule.form_status === 'KAPAL BERANGKAT' || _vm.form.schedule.form_status === 'KEGIATAN DIBATALKAN' ? _c('div', [_c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.schedule.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all'),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1) : _c('div', [!_vm.permissionTenantAndSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar Tug Boat *",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_sandar", $$v);
            },
            expression: "form.tug_boat.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.tug_boat.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', ''),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 3616976108)
  })], 1)], 1)], 1)], 1) : _vm._e(), !_vm.isExistDetailID && !_vm.form.tug_boat.isNew ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.breakSuggestionValueTB();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )')
    }
  })], 1), _c('span', [_c('small', [_c('strong', [_vm._v("Abaikan jika data master kapal yang otomatis teriisi sudah benar")])])])], 1)]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-danger",
      "block": "",
      "disabled": !_vm.form.schedule.no_pkk_tug_boat || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Buat data baru")])], 1), _c('span', [_vm._v("Contoh kasus: data kapal belum pernah dilakukan penginputan.")])], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-warning",
      "block": "",
      "disabled": !_vm.form.schedule.no_pkk_tug_boat || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Revisi data tug boat, Jika ada data yang tidak sesuai.")])], 1), _c('span', [_vm._v("Contoh kasus: kesalahan penulisan")])], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTB({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('')
    }
  })], 1)], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "success",
      "block": "",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.saveToUpdateDMKTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('SIMPAN')
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.$store.state.app.roleName !== 'viewer' && (_vm.$can('manage', 'jetty') || _vm.$can('SAL', '')) && _vm.isExistDetailID && !_vm.statePayloadDMKTB && !_vm.statePayloadDMKTBMaster ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTBMaster({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Ganti/Pilih Kapal")])], 1) : _vm._e()], 1), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTBMaster({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span')], 1)], 1) : _vm._e(), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "success",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.jwtGantiKapalTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', [_vm._v("Simpan")])], 1)], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', [_c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s("Hanya jettyman yang dapat mengganti TugBoat.")
    }
  })], 1)])])], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _c('tab-content', {
    attrs: {
      "title": "Crew Kapal",
      "icon": "feather icon-anchor"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Crew Kapal")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Untuk keperluan monitoring jumlah crew kapal yang masuk area PT SAL.")])])])], 1), _c('validation-observer', {
    ref: "refVisitor",
    attrs: {
      "tag": "form"
    }
  }, [_vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', [_c('b-card', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" " + _vm._s(this.form.dm_river_boat_hm.boat_name) + " ")])])]), _c('b-form-group', {
    attrs: {
      "label": "Jumlah Crew Kapal",
      "label-for": "vi-schedule-count_crew_mb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah Crew Kapal",
      "vid": "Jumlah Crew Kapal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref25) {
        var errors = _ref25.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-count_crew_mb",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Jumlah Crew Kapal'
          },
          model: {
            value: _vm.form.schedule.count_crew_mb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "count_crew_mb", $$v);
            },
            expression: "form.schedule.count_crew_mb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3517386289)
  })], 1)], 1)], 1), _c('b-col', [_c('b-card', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v(" " + _vm._s(this.form.tug_boat.name_tug_boat) + " ")])])]), _c('b-form-group', {
    attrs: {
      "label": "Jumlah Crew Kapal",
      "label-for": "vi-schedule-count_crew_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah Crew Kapal",
      "vid": "Jumlah Crew Kapal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref26) {
        var errors = _ref26.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-count_crew_tb",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Jumlah Crew Kapal'
          },
          model: {
            value: _vm.form.schedule.count_crew_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "count_crew_tb", $$v);
            },
            expression: "form.schedule.count_crew_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1758736081)
  })], 1)], 1)], 1)], 1)], 1) : _vm.form.schedule.jenis_kapal === 'Tug Boat' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Crew Kapal",
      "label-for": "vi-schedule-count_crew_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah Crew Kapal",
      "vid": "Jumlah Crew Kapal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref27) {
        var errors = _ref27.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-count_crew_tb",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Jumlah Crew Kapal'
          },
          model: {
            value: _vm.form.schedule.count_crew_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "count_crew_tb", $$v);
            },
            expression: "form.schedule.count_crew_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Crew Kapal",
      "label-for": "vi-schedule-count_crew_mb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah Crew Kapal",
      "vid": "Jumlah Crew Kapal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref28) {
        var errors = _ref28.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-count_crew_mb",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Jumlah Crew Kapal'
          },
          model: {
            value: _vm.form.schedule.count_crew_mb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "count_crew_mb", $$v);
            },
            expression: "form.schedule.count_crew_mb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.isExistDetailID && !_vm.permissionTenantAndSAL() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiCrew
    },
    on: {
      "click": function click($event) {
        return _vm.saveCrewKapal();
      }
    }
  }, [!_vm.loadingRealisasiCrew ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiCrew ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Jumlah Crew Kapal")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mb-1"
  })], 1), _vm.form.schedule.jenis_kapal !== 'Tug Boat' || false ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step3.title,
      "icon": _vm.wizard.first.step3.icon
    }
  }, [_c('validation-observer', {
    ref: "scheduleHuluMigasRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step3.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step3.content.subtitle) + ".")])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Kapal *",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Kapal",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref29) {
        var errors = _ref29.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Asal Kapal',
              disabled: _vm.permissionTenantAndSAL()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref30) {
              var suggestion = _ref30.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 4037036537)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Posisi Jetty *",
      "label-for": "vi-schedule-rencana_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Posisi Jetty",
      "vid": "Rencana Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref31) {
        var errors = _ref31.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.rencana_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "rencana_position_jetty", $$v);
            },
            expression: "form.schedule.rencana_position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2247304218)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Baris Posisi Jetty *",
      "label-for": "vi-schedule-rencana_baris_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Baris Posisi Jetty",
      "vid": "Rencana Baris Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref32) {
        var errors = _ref32.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_baris_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Baris Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.rencana_baris_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "rencana_baris_position_jetty", $$v);
            },
            expression: "form.schedule.rencana_baris_position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2663226597)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref33) {
        var errors = _ref33.errors;
        return [_vm.form.schedule.form_status === 'KAPAL BERANGKAT' || _vm.form.schedule.form_status === 'KEGIATAN DIBATALKAN' ? _c('div', [_c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.schedule.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all'),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1) : _c('div', [!_vm.permissionTenantAndSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "rencana_sandar", $$v);
            },
            expression: "form.schedule.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.schedule.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', ''),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }], null, false, 818779320)
  })], 1)], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingChange || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.validationModifiedFormData();
      }
    }
  }, [!_vm.loadingChange ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingChange ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(" Simpan Perubahan Form Data ")])], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1)], 1) : _vm._e(), _vm.$router.currentRoute.params.id && _vm.form.schedule.jenis_kapal !== 'Tug Boat' || false ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step4.title,
      "icon": _vm.wizard.first.step4.icon
    }
  }, [_vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Barge")]), _c('h3', [_vm._v("Document " + _vm._s(_vm.form.dm_river_boat_hm.boat_name))])], 1)]) : _vm._e(), _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" [1]. " + _vm._s(_vm.wizard.first.step4.content.title) + " ")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step4.content.subtitle) + ".")])])])], 1), _vm.loadingFileStorage ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('DropZoneUploadV1', {
    key: _vm.isHaveFile.length,
    attrs: {
      "uri": _vm.BACKEND_URL,
      "update-i-d": _vm.form.form_chain_hm_id,
      "accept-file": "application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx",
      "max-files": 10,
      "re-upload-title": "Re Upload Pengajuan RKBM  (Max 10 File Excel)",
      "is-have-file": _vm.isHaveFile,
      "modelName": 'form_chain_hm',
      "info": "Maksimal 10 Berkas",
      "disable-action": !_vm.editAbleDocument(_vm.user_id)
    },
    on: {
      "callBack": _vm.dropZoneCallBack
    }
  })], 1), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-card', [_c('span', [_vm._v(" Nama Tugboat ( "), _c('strong', [_vm._v("keterangan")]), _vm._v(" ini sebagai pengajuan RKBM ) * ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "vi-nama_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Nama TugBoat"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref34) {
        var errors = _ref34.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-nama_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "TUGBOAT"
          },
          model: {
            value: _vm.form.optional.nama_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.optional, "nama_tb", $$v);
            },
            expression: "form.optional.nama_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.nama_tb ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'nama_tb' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2153844804)
  })], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "disabled": _vm.form.optional.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.saveNamaTB(true);
      }
    }
  }, [!_vm.form.optional.isLoading ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.form.optional.isLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Simpan TugBoat ")], 1), _c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s("Menyimpan informasi Nama TugBoat akan mengirimkan notifikasi pengajuan RKBM Telah diajukan.")
    }
  })], 1)])])], 1) : _c('b-card', [_c('b-button', {
    attrs: {
      "disabled": _vm.form.optional.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.saveNamaTB(false);
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "SendIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Kirim Notifikasi RKBM Telah Diajukan ")], 1), _c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s("Sudah mengirimkan notifikasi pengajuan RKBM.")
    }
  })], 1)])])], 1), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Tug Boat")]), _c('h3', [_vm._v("Document " + _vm._s(_vm.form.tug_boat.name_tug_boat))])], 1)]) : _vm._e(), _vm.form.tug_boat.name_tug_boat ? _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("[2]. Document File Ijin Bunker")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("max size 10 MB ( .xls, .xlsx, pdf , jpeg , jpg , png ).")])]), _vm.loadingFileStorage ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('validation-observer', {
    ref: "tbbmRulesFileBunker",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor Ijin Bunker",
      "label-for": "vi-schedule-no_ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Ijin Bunker",
      "vid": "Nomor Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref35) {
        var errors = _ref35.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ijin_bunker",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Nomor Ijin BUnker' : 'Nomor Ijin BUnker'
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker", $$v);
            },
            expression: "form.schedule.no_ijin_bunker"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2582180954)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume (Total Bunker)",
      "label-for": "vi-dm_river_boat-total_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Volume (Total Bunker) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref36) {
        var errors = _ref36.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-total_bunker",
            "raw": true,
            "options": _vm.options.number,
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.total_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "total_bunker", $$v);
            },
            expression: "form.schedule.total_bunker"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2143609918)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "vi-schedule-ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Ijin Bunker",
      "vid": "Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref37) {
        var errors = _ref37.errors;
        return [_c('BFormFile', {
          attrs: {
            "placeholder": "Choose a file Ijin Bunker or drop it here...",
            "drop-placeholder": "Drop file here...",
            "state": errors.length > 0 ? false : null,
            "accept": ".xls, .xlsx, .pdf, .jpeg, .jpg, .png",
            "no-drop": ""
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker_file,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker_file", $$v);
            },
            expression: "form.schedule.no_ijin_bunker_file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm._l(_vm.no_ijin_bunker_file, function (item, index) {
          return _c('app-timeline-item', {
            key: index
          }, [_c('div', {
            staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          }), _c('p', [_c('b-img', {
            staticClass: "mr-1",
            attrs: {
              "src": require('@/assets/images/icons/xls.png'),
              "height": "auto",
              "width": "20"
            }
          }), _c('span', {
            staticClass: "align-bottom"
          }, [_c('a', {
            staticStyle: {
              "color": "grey"
            },
            attrs: {
              "href": item.download_link,
              "target": "_blank",
              "rel": "noopener noreferrer"
            }
          }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
        })];
      }
    }], null, false, 894219283)
  })], 1)], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.saveIjinBunker();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Ijin Bunker')
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.$router.currentRoute.params.id && _vm.form.schedule.jenis_kapal !== 'Tug Boat' || false ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step5.title,
      "icon": _vm.wizard.first.step5.icon
    }
  }, [_vm.form.schedule.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck' ? _c('div', [_c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Keterangan "), _c('strong', [_vm._v(" ( Only Sea Truck )")])])]), _c('br'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "vi-keterangan_sea_truck"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Keterangan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref38) {
        var errors = _ref38.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-keterangan_sea_truck",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Keterangan ( Only Sea Truck)"
          },
          model: {
            value: _vm.form.schedule.keterangan_sea_truck,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "keterangan_sea_truck", $$v);
            },
            expression: "form.schedule.keterangan_sea_truck"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.schedule.keterangan_sea_truck ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'keterangan_sea_truck' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2783805639)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loadingButton
    },
    on: {
      "click": function click($event) {
        return _vm.simpanKeterangaSeaTruck();
      }
    }
  }, [!_vm.loadingButton ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingButton ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', [_vm._v("Simpan Keterangan")])], 1)], 1)], 1)], 1) : _vm._e(), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_vm.form.form_hulu_migas_id ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v("Tab Pipeline")])])];
      },
      proxy: true
    }], null, false, 3074488329)
  }, [_c('TablePipeLine', {
    key: _vm.form.table_pipe_line,
    ref: "TablePipeLineOutside",
    staticStyle: {
      "display": "inline-grid"
    },
    attrs: {
      "kegiatan-select-data": "".concat(_vm.form.schedule.kegiatan),
      "fhm-i-d": _vm.form.form_hulu_migas_id,
      "form-chain-hm-i-d": _vm.form.form_chain_hm_id,
      "disable-crud": _vm.form.schedule.form_status,
      "data-high-light": {
        kapal: _vm.form.dm_river_boat_hm.boat_name,
        no_pkk: _vm.form.schedule.no_pkk,
        rencana_sandar: _vm.form.schedule.rencana_sandar
      }
    },
    on: {
      "render-component": _vm.renderComponent
    }
  })], 1) : _vm._e(), _vm.form.form_hulu_migas_id ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v("Tab Semi Mekanis")])])];
      },
      proxy: true
    }], null, false, 1476331535)
  }, [_c('TableMH', {
    key: _vm.form.table_mh,
    ref: "TableManualHandlingOutside",
    staticStyle: {
      "display": "inline-grid"
    },
    attrs: {
      "kegiatan-select-data": "".concat(_vm.form.schedule.kegiatan),
      "fhm-i-d": _vm.form.form_hulu_migas_id,
      "form-chain-hm-i-d": _vm.form.form_chain_hm_id,
      "disable-crud": _vm.form.schedule.form_status,
      "data-high-light": {
        kapal: _vm.form.dm_river_boat_hm.boat_name,
        no_pkk: _vm.form.schedule.no_pkk,
        rencana_sandar: _vm.form.schedule.rencana_sandar
      },
      "is-realisasi": false
    },
    on: {
      "render-component": _vm.renderComponent
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step1.title,
      "icon": _vm.wizard.second.step1.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step1.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step1.content.subtitle) + ".")])])])], 1), _c('br'), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" Sandar & Berangkat ")]), _c('br'), _c('small', [_vm._v("Tabs Menu")])])];
      },
      proxy: true
    }], null, false, 2015253494)
  }, [_c('hr'), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "left"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.dm_river_boat_hm.boat_name)
          }
        }), _c('br'), _c('small', [_vm._v("Main Boat")])])];
      },
      proxy: true
    }], null, false, 2433739170)
  }, [_c('b-row', [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Main Boat")]), _c('h3', [_vm._v(" Realisasi Kapal " + _vm._s(this.form.dm_river_boat_hm.boat_name) + " ")]), _c('span', [_vm._v(_vm._s(this.form_realisasi.rka1))]), _c('br'), _c('span', [_vm._v("Sandar → Berangkat")])], 1)])]) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' && _vm.$store.state.app.roleName !== 'viewer' ? '3' : '3',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-schedule-sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref39) {
        var errors = _ref39.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' && _vm.$store.state.app.roleName !== 'viewer' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "sandar_start_time", $$v);
            },
            expression: "form.schedule.sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.schedule.sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3683235268)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '3' : '3',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref40) {
        var errors = _ref40.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tujuan-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueTK,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Tujuan Kapal'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'schedule', 'tujuan_kapal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal',
                flagTujuanOps: 'tujuan_kapal_is_new'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref41) {
              var suggestion = _ref41.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.tujuan_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "tujuan_kapal", $$v);
            },
            expression: "form.schedule.tujuan_kapal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 832003491)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? '4' : '6',
      "cols": "6"
    }
  }, [!_vm.form.form_tug_boat_id && _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-alert', {
    staticClass: "mt-1",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Jettyman terlebih dahulu melakukan ganti atau pilih "), _c('strong', [_vm._v("Tug Boat")]), _vm._v(" untuk keberangkatan. ")])]) : _vm._e(), _vm.form.schedule.jenis_kapal !== 'Barge' || _vm.form.form_tug_boat_id ? _c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-schedule-sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref42) {
        var errors = _ref42.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.schedule.sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "sandar_end_time", $$v);
            },
            expression: "form.schedule.sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.schedule.sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4088557578)
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA1
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA1();
      }
    }
  }, [!_vm.loadingRelRKA1 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA1 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi")])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v("Realisasi Posisi Jetty")])])]), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Histori Kapal Awal Sandar / Pindah Jetty "), _c('strong', [_vm._v("Diisi Admin SAL / Jetty Man")])])]), _c('br'), _c('NestedIO', {
    key: _vm.form.table_jetty_position,
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.form.form_hulu_migas_id,
      "model": "form_hulu_migas",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "disable-action": !!this.form.schedule.sandar_start_time,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": " Simpan Realisasi"
    },
    on: {
      "sentNotification": _vm.sentNotification,
      "alreadyRealisasi": _vm.alreadyRealisasi
    }
  })], 1), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.tug_boat.name_tug_boat)
          }
        }), _c('br'), _c('small', [_vm._v("Tug Boat")])])];
      },
      proxy: true
    }], null, false, 359963974)
  }, [!_vm.form.form_tug_boat_id ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Jettyman terlebih dahulu melakukan ganti atau pilih "), _c('strong', [_vm._v("Tug Boat")]), _vm._v(" untuk keberangkatan. ")])]) : _vm._e(), _vm.form.form_tug_boat_id ? _c('div', [_c('b-row', [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Tug Boat")]), _c('h3', [_vm._v(" Realisasi Kapal " + _vm._s(this.form.tug_boat.name_tug_boat) + " ")]), _c('span', [_vm._v(_vm._s(this === null || this === void 0 ? void 0 : (_this$form_realisasi = this.form_realisasi) === null || _this$form_realisasi === void 0 ? void 0 : _this$form_realisasi.rka2))]), _c('br'), _c('span', [_vm._v("Sandar → Berangkat")])], 1)])]) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? '6' : '6',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-tug_boat-tug_sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref43) {
        var errors = _ref43.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_start_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 210751225)
  })], 1)], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? '4' : '6',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-tug_boat-tug_sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref44) {
        var errors = _ref44.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_end_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2694625975)
  })], 1)], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA2
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA2();
      }
    }
  }, [!_vm.loadingRelRKA2 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA2 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi")])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v("Realisasi Posisi Jetty")])])]), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Histori Kapal Awal Sandar / Pindah Jetty "), _c('strong', [_vm._v("Diisi Admin SAL / Jetty Man")])])]), _c('br'), _c('NestedIO', {
    key: _vm.form.table_jetty_position,
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.form.form_tug_boat_id,
      "model": "form_tug_boat",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "disable-action": !!this.form.tug_boat.tug_sandar_start_time,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": " Simpan Realisasi"
    },
    on: {
      "sentNotification": _vm.sentNotification,
      "alreadyRealisasi": _vm.alreadyRealisasi
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "WatchIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" IDLE TIME ")]), _c('br'), _c('small', [_vm._v("Tabs Menu")])])];
      },
      proxy: true
    }], null, false, 2571445977)
  }, [_c('div', {
    staticClass: "tableidle"
  }, [_c('h2', [_vm._v(" Total Iddle Time : "), _c('span', {
    staticStyle: {
      "color": "grey"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.totalIdleTime))])])]), _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.idle.apiEndpoint,
      "title-tbl": "",
      "sub-title-tbl": "",
      "fields": _vm.idle.fields.filter(function (field) {
        return field.gone === true;
      }),
      "new-data-template": _vm.idleNewDataTemplate,
      "resolve-fetch-response": _vm.idleResolveFetchResponse,
      "resolve-fetch-url": _vm.idleResolveFetchUrl,
      "bottom-label": "",
      "bottom-icon": "TruckIcon",
      "info-page-ability": "Manage (All / JettyMan / Security)",
      "placeholder-search": "Idle Time"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionSalOnly() ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step2.title,
      "icon": _vm.wizard.second.step2.icon
    }
  }, [_c('validation-observer', {
    ref: "adminSalRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step2.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step2.content.subtitle) + ".")])])])], 1), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [_c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.dm_river_boat_hm.boat_name)
          }
        }), _c('br'), _c('small', [_vm._v("Main Boat")])])];
      },
      proxy: true
    }], null, false, 2433739170)
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[1] Status Kapal Sesuai INAPORTNET *",
      "label-for": "vi-schedule-status_kapal_mb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Status Kapal Sesuai INAPORTNET Main Boat",
      "vid": "Status Kapal Sesuai INAPORTNET Main Boat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref45) {
        var errors = _ref45.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-schedule-status_kapal_mb",
            "suggestions": [{
              data: _vm.status_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueManual,
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: "Pilih / Ketik Manual",
              disabled: _vm.permissionTenantAndSAL()
            }
          },
          on: {
            "input": function input($event) {
              return _vm.getSuggestionValueManualInput($event);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref46) {
              var suggestion = _ref46.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(suggestion.item.status_kapals))])])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.status_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "status_kapal", $$v);
            },
            expression: "form.schedule.status_kapal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]) + " ")])];
      }
    }], null, false, 3628231220)
  })], 1)], 1), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[2] Nomor Pindah",
      "label-for": "vi-schedule-no_Pindah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Pindah",
      "vid": "Nomor Pindah",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref47) {
        var errors = _ref47.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_Pindah",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor Pindah'
          },
          model: {
            value: _vm.form.schedule.no_pindah,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah", $$v);
            },
            expression: "form.schedule.no_pindah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1409682110)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[3] Nomor RPKRO",
      "label-for": "vi-schedule-no_rpkro"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO",
      "vid": "Nomor RPKRO",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref48) {
        var errors = _ref48.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor RPKRO'
          },
          model: {
            value: _vm.form.schedule.no_rpkro,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro", $$v);
            },
            expression: "form.schedule.no_rpkro"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2332605552)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[4] Nomor RKBM",
      "label-for": "vi-schedule-no_rkbm"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RKBM",
      "vid": "Nomor RKBM",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref49) {
        var errors = _ref49.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rkbm",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor RKBM'
          },
          model: {
            value: _vm.form.schedule.no_rkbm,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rkbm", $$v);
            },
            expression: "form.schedule.no_rkbm"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1646898514)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[5] Nomor PBMBB",
      "label-for": "vi-schedule-no_pbmbb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor pbmbb",
      "vid": "Nomor pbmbb",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref50) {
        var errors = _ref50.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pbmbb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor PBMBB'
          },
          model: {
            value: _vm.form.schedule.no_pbmbb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pbmbb", $$v);
            },
            expression: "form.schedule.no_pbmbb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2240498235)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[6] Nomor SPOG ( kedatangan )",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref51) {
        var errors = _ref51.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: SPOG.01"
          },
          model: {
            value: _vm.form.schedule.no_spog,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog", $$v);
            },
            expression: "form.schedule.no_spog"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1427050475)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[7] Nomor SPOG ( keberangkatan )",
      "label-for": "vi-schedule-no_spog_brk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG ( keberangkatan )",
      "vid": "Nomor SPOG ( keberangkatan )",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref52) {
        var errors = _ref52.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_brk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: SPOG.01"
          },
          model: {
            value: _vm.form.schedule.no_spog_brk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_brk", $$v);
            },
            expression: "form.schedule.no_spog_brk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1800223531)
  })], 1)], 1), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA3
    },
    on: {
      "click": function click($event) {
        return _vm.validationModifiedData();
      }
    }
  }, [!_vm.loadingRelRKA3 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA3 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("( 1 ) Simpan Realisasi")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          },
          domProps: {
            "textContent": _vm._s(_vm.form.tug_boat.name_tug_boat)
          }
        }), _c('br'), _c('small', [_vm._v("Tug Boat")])])];
      },
      proxy: true
    }], null, false, 359963974)
  }, [!_vm.form.form_tug_boat_id ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Jettyman terlebih dahulu melakukan ganti atau pilih "), _c('strong', [_vm._v("Tug Boat")]), _vm._v(" untuk keberangkatan. ")])]) : _vm._e(), !_vm.form.form_tug_boat_id ? _c('br') : _vm._e(), _c('validation-observer', {
    ref: "validationModifiedDataTB",
    attrs: {
      "tag": "form"
    }
  }, [_vm.form.form_tug_boat_id ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[1] Status Kapal Sesuai INAPORTNET *",
      "label-for": "vi-schedule-status_kapal_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "[1] Status Kapal Sesuai INAPORTNET *",
      "vid": "[1] Status Kapal Sesuai INAPORTNET *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref53) {
        var errors = _ref53.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-schedule-status_kapal_tb",
            "suggestions": [{
              data: _vm.status_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueManualTB,
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: "Pilih / Ketik Manual",
              disabled: _vm.permissionTenantAndSAL()
            }
          },
          on: {
            "input": function input($event) {
              return _vm.getSuggestionValueManualInputTB($event);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref54) {
              var suggestion = _ref54.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(suggestion.item.status_kapals))])])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.status_kapal_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "status_kapal_tb", $$v);
            },
            expression: "form.schedule.status_kapal_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]) + " ")])];
      }
    }], null, false, 3185457284)
  })], 1)], 1), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[2] Nomor Pindah",
      "label-for": "vi-schedule-no_Pindahtb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Pindah",
      "vid": "Nomor Pindah",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref55) {
        var errors = _ref55.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_Pindahtb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor Pindah'
          },
          model: {
            value: _vm.form.schedule.no_pindah_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah_tb", $$v);
            },
            expression: "form.schedule.no_pindah_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1424314283)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[3] Nomor RPKRO",
      "label-for": "vi-schedule-no_rpkro_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO",
      "vid": "Nomor RPKRO",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref56) {
        var errors = _ref56.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor RPKRO'
          },
          model: {
            value: _vm.form.schedule.no_rpkro_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro_tb", $$v);
            },
            expression: "form.schedule.no_rpkro_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1679809786)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[4] Nomor SPOG ( kedatangan )",
      "label-for": "vi-schedule-no_spog_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref57) {
        var errors = _ref57.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_tb",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Nomor SPOG ( Kedatangan )"
          },
          model: {
            value: _vm.form.schedule.no_spog_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb", $$v);
            },
            expression: "form.schedule.no_spog_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2643059081)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[5] Nomor SPOG ( keberangkatan )",
      "label-for": "vi-schedule-no_spog_tb_brk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG ( keberangkatan )",
      "vid": "Nomor SPOG ( keberangkatan )",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref58) {
        var errors = _ref58.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_tb_brk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Nomor SPOG ( Keberangkatan )"
          },
          model: {
            value: _vm.form.schedule.no_spog_tb_brk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb_brk", $$v);
            },
            expression: "form.schedule.no_spog_tb_brk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1117207411)
  })], 1)], 1), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA4
    },
    on: {
      "click": function click($event) {
        return _vm.validationModifiedDataTB();
      }
    }
  }, [!_vm.loadingRelRKA4 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA4 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("( 2 ) Simpan Realisasi ")])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1) : _c('span', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[1] Status Kapal Sesuai INAPORTNET *",
      "label-for": "vi-schedule-status_kapal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Status Kapal Sesuai INAPORTNET",
      "vid": "Status Kapal Sesuai INAPORTNET",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref59) {
        var errors = _ref59.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-schedule-status_kapal",
            "suggestions": [{
              data: _vm.status_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueManual,
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: "Pilih / Ketik Manual",
              disabled: _vm.permissionTenantAndSAL()
            }
          },
          on: {
            "input": function input($event) {
              return _vm.getSuggestionValueManualInput($event);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref60) {
              var suggestion = _ref60.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(suggestion.item.status_kapals))])])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.status_kapal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "status_kapal", $$v);
            },
            expression: "form.schedule.status_kapal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]) + " ")])];
      }
    }], null, false, 2597877156)
  })], 1)], 1), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[2] Nomor Pindah",
      "label-for": "vi-schedule-no_Pindah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Pindah",
      "vid": "Nomor Pindah",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref61) {
        var errors = _ref61.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_Pindah",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor Pindah'
          },
          model: {
            value: _vm.form.schedule.no_pindah,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah", $$v);
            },
            expression: "form.schedule.no_pindah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1167752382)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[3] Nomor RPKRO",
      "label-for": "vi-schedule-no_rpkro"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO",
      "vid": "Nomor RPKRO",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref62) {
        var errors = _ref62.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor RPKRO'
          },
          model: {
            value: _vm.form.schedule.no_rpkro,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro", $$v);
            },
            expression: "form.schedule.no_rpkro"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1282788976)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[4] Nomor RKBM",
      "label-for": "vi-schedule-no_rkbm"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RKBM",
      "vid": "Nomor RKBM",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref63) {
        var errors = _ref63.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rkbm",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor RKBM'
          },
          model: {
            value: _vm.form.schedule.no_rkbm,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rkbm", $$v);
            },
            expression: "form.schedule.no_rkbm"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 325271378)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[5] Nomor PBMBB",
      "label-for": "vi-schedule-no_pbmbb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor pbmbb",
      "vid": "Nomor pbmbb",
      "rules": {
        required_if: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref64) {
        var errors = _ref64.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pbmbb",
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', '') || !_vm.$can('SAL', ''),
            "placeholder": (_vm.$router.currentRoute.params.id ? !_vm.$can('SAL', '') && !_vm.$can('manage', 'all') : null) ? 'Diisi Admin SAL' : 'Nomor PBMBB'
          },
          model: {
            value: _vm.form.schedule.no_pbmbb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pbmbb", $$v);
            },
            expression: "form.schedule.no_pbmbb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2116885563)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[6] Nomor SPOG ( kedatangan )",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref65) {
        var errors = _ref65.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: SPOG.01"
          },
          model: {
            value: _vm.form.schedule.no_spog,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog", $$v);
            },
            expression: "form.schedule.no_spog"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1427050475)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[7] Nomor SPOG ( keberangkatan )",
      "label-for": "vi-schedule-no_spog_brk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG ( keberangkatan )",
      "vid": "Nomor SPOG ( keberangkatan )",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref66) {
        var errors = _ref66.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_brk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: SPOG.01"
          },
          model: {
            value: _vm.form.schedule.no_spog_brk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_brk", $$v);
            },
            expression: "form.schedule.no_spog_brk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1800223531)
  })], 1)], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA3
    },
    on: {
      "click": function click($event) {
        return _vm.validationModifiedData();
      }
    }
  }, [!_vm.loadingRelRKA3 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA3 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi ")])], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1)], 1) : _vm._e()], 1)], 1)]), _vm.$router.currentRoute.params.id !== undefined ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "to": {
        name: 'dashboard-monitoring-rrv-hulu-migas-summary',
        params: {
          id: _vm.form.form_chain_hm_id,
          api: 'form_chain_hm',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EyeIcon"
    }
  }), _vm._v(" Summary ")], 1), _c('hr'), _c('b-badge', {
    staticClass: "mr-1 mb-1 text-center",
    attrs: {
      "href": "#",
      "pill": "",
      "variant": "secondary"
    }
  }, [_c('strong', [_c('span', {
    domProps: {
      "textContent": _vm._s("Form Perencanaan Created BY ".concat(_vm.form.created_by))
    }
  })]), _c('br')]), _c('br'), _vm._v(" Created AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.created_at)) + " - Last Updated AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.updated_at)) + " ")], 1) : _vm._e(), _c('FormModal', {
    key: "ModalVerification".concat(_vm.keyModalVerification),
    attrs: {
      "uri": "/verification_hm",
      "title": "Form Verification",
      "is-modal-active": _vm.dataModalVerification,
      "payload": _vm.dataPayloadModalVerification,
      "action": "is_add",
      "column": _vm.columnModalVerification
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.dataModalVerification = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.dataModalVerification = $event;
      },
      "closeModal": _vm.methodModalVerification
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }